import React from 'react';
import {graphql} from 'gatsby';
import PropTypes from 'prop-types';

import t from '../lib/translate';

import Layout from '../layouts/default';

import CmsNewsList from '../components/single-news-list';
import Helmet from '../components/helmet';
import { StructuredData } from '../components/structured-data';
import CmsImageComponent from '../content-elements/image';
import StructureText from '../content-elements/text';
import Breadcrumb from '../component-elements/breadcrumb';

import Section from '../component-elements/content-section';

import * as NewsSingleStyle from '../components/single-news-list/component.module.scss';

const SinglePost = ({
						data: {
							page: {
								metaRobots,
								metaCanonical,
								seoMetaTags: helmetProps,
								teaser: abstract,
								...page
							},
							relatedNews: {
								nodes: relatedNews
							},
							datoCmsConfig: {
								pageNewslist: listPage,
								newsDetailPath
							}
						},
						pageContext
					}) => (
		<Layout textcolorHeader="black" textcolorHeaderMobile="black">
			<Breadcrumb
					items={[
						{id: listPage.id, name: listPage.name, url: listPage.fullUrlPath},
						{id: page.title, name: page.title}
					]}
			/>

			<div className={NewsSingleStyle.contentHeaderCms}>
				<div className={NewsSingleStyle.contentHeaderText}>
					<div className="container-fluid">
						<p className={NewsSingleStyle.contentHeaderDate}>{page.date}</p>
						<h1>{page.title}</h1>
					</div>
				</div>
			</div>

			<Section background="Weiß">
				<div className={`${NewsSingleStyle.newsSingleContent} col-lg-16`}>
					<p className={NewsSingleStyle.newsAbstract}>
						{abstract}
					</p>
					<div className={NewsSingleStyle.newsImageContainer}>
						<figure>
							<CmsImageComponent image={page.image} />
							{page.image.title && <figcaption>
								{page.image.title}
							</figcaption>}
						</figure>
					</div>
					<StructureText
							content={page.content}
					/>
				</div>
			</Section>

			<Section noGrid background="Weiß">
				<header className="section-header">
					<h4>
						{t`news_list_related_posts_headline`}
					</h4>
				</header>
				<CmsNewsList
						items={relatedNews}
				/>
			</Section>

			<Helmet
					tags={helmetProps}
					metaRobots={metaRobots}
					metaCanonical={metaCanonical}
					title={page.seo?.title}
					titleFallback={t('news_details_title_fallback', page.title)}
					description={page.seo?.description}
					descriptionFallback={
						abstract.length > 160 ? `${abstract.slice(0, 157).trim()}...` : abstract
					}
					url= {`/${newsDetailPath}/${pageContext.url}/`}
			/>

			<StructuredData
					type="NewsArticle"
					{...{
						headline: page.title,
						description: page.teaserNode?.internal?.content,
						image: page.image?.gatsbyImageData.images.fallback.src,
						datePublished: page.mrDate,
						author: {
							'@type': 'Organization',
							name: 'Juwelier Roller'
						},
						publisher: {
							'@type': 'Organization',
							name: 'Juwelier Roller',
							logo: {
								'@type': 'ImageObject',
								url: `${process.env.GATSBY_WEB_URL}/logo.png`
							}
						}
					}}
			/>
		</Layout>
);

SinglePost.propTypes = {
	data: PropTypes.objectOf(PropTypes.any).isRequired,
	pageContext: PropTypes.objectOf(PropTypes.any).isRequired
};

export default SinglePost;

export const query = graphql`
query querySinglePost($url: String, $newsCategories: [String], $lang: String) {
    datoCmsConfig(locale: {eq: $lang}) {
        pageNewslist {
            id
            name
            fullUrlPath
        }
      	newsDetailPath
    }

    page: datoCmsNews(url: {eq: $url}, locale: {eq: $lang}) {
        id
        seoMetaTags {
            __typename
            tags
        }
        seo {
            title
            description
        }
        metaCanonical
        metaRobots
        date(formatString: "DD.MM.YYYY")
        mrDate: date(formatString: "YYYY-MM-DD")
        title
        teaser
        categories {
            name
        }
        image {
            alt
            title
            gatsbyImageData(
                forceBlurhash: true
                imgixParams: {
                    w: "1080"
                    auto: "compress"
                    q: 70
                    dpr: 1.5
                }
            )
        }
        content {
            __typename
            value
            blocks {
                __typename
                ... on DatoCmsImageGallery {
                    ...imageGallery
                    originalId
                }
                ... on DatoCmsImage {
                    originalId
                    image {
                        alt
                        title
                        gatsbyImageData(
                            forceBlurhash: true
                            imgixParams: {
                                w: "1080"
                                auto: "compress"
                                q: 70
                                dpr: 1.5
                            }
                        )
                    }
                }
            }
            links {
                __typename
                ... on DatoCmsPage {
                    originalId
                    name
                    fullUrlPath
                }
                ... on DatoCmsNews {
                    originalId
                    title
                    fullUrlPath
                }
            }
        }
    }

    relatedNews: allDatoCmsNews(
        sort: {fields: date, order: DESC},
        filter: {url: {ne: $url}, locale: {eq: $lang} categories: {elemMatch: {id: {in: $newsCategories }}}},
        limit: 3
    ) {
        nodes {
            id
            title
            fullUrlPath
            date(formatString: "DD.MM.YYYY")
            categories { name }
            image { ...genericTeaserImage }
        }
    }
}
`;
